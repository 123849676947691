// 按需页面加载接口
import request from "./request"

export default {
    login: data => {
        let url = "/auth/login"
        return request(url, data, "POST", false, '登录中...');
    },
    // 获取首页banner数据
    getBannerList: () => request('/arAndBan/ciarticle/getBannerList'),
    // 获取首页统计数据
    getCountNum: () => request('/arAndBan/ciarticle/DataNum'),
    // 获取数据中心最新发布的4条动态
    getCenterArticleListTop4: () => request('/arAndBan/ciarticle/arClassList?page=1&limit=4&pid=3'),
    // 根据文章类型查询文章列表
    getArticleListByClassId: (page,limit,pid,classId) => request(`/arAndBan/ciarticle/arClassList?page=${page}&limit=${limit}&pid=${pid}&classId=${classId}`),
    // 根据类型ID获取文章类型
    getArticleTypes: pid => request('/arAndBan/ciarticle/ArticleClassList?pid='+pid),
    // 根据文章ID获取文章内容
    getArticleInfo: (articleId) => request('/arAndBan/ciarticle/ArticleDetail?articleId='+articleId),
    // 新闻摘要提取
    newsScreen: (params) => request('/ai-api/ai/newsSummary', params,'post'),
    // 获取案例
    arClassList: (page, limit, pid, classId) => request(`/arAndBan/ciarticle/arClassList?page=${page}&limit=${limit}&pid=${pid}&classId=${classId}`)

}