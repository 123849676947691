<template>
  <div class="foot">
    <div class="flex flex-space fontcontext">
      <div>
        <div class="prititle">生态服务能力</div>
        <a class="sectitle" @click="toPage('/services', 1, item.value)" target="_blank"
          :class="{ mt28: index > 0, mt40: index == 0 }" v-for="(item, index) in this.topMenus.services"
          :key="index">{{ item.label }}</a>
      </div>
      <div>
        <div class="prititle">应用创新</div>
        <a class="sectitle" @click="toPage('/innovation', 2, item.value)" target="_blank"
          :class="{ mt28: index > 0, mt40: index == 0 }" v-for="(item, index) in this.topMenus.innovation"
          :key="index">{{ item.label }}</a>
      </div>
      <div>
        <div class="prititle">中心动态</div>
        <a class="sectitle" @click="toPage('/centralnews', 3, item.value)" target="_blank"
          :class="{ mt28: index > 0, mt40: index == 0 }" v-for="(item, index) in this.topMenus.centralnews"
          :key="index">{{ item.label }}</a>
      </div>
      <div>
        <div class="prititle">关于我们</div>
        <a class="sectitle mt40" @click="toPage('/aboutus', 4, 1)">企业介绍</a>
        <a class="sectitle mt28" @click="toPage('/aboutus', 4, 2)">专家团队</a>
        <a class="sectitle mt28" @click="toPage('/aboutus', 4, 3)">加入我们</a>
        <a class="sectitle mt28" @click="toPage('/aboutus', 4, 4)">联系我们</a>
      </div>
      <div>
        <div class="prititle">联系方式</div>
        <span class="sectitle mt40">电话：{{ $web.phone }}</span>
        <span class="sectitle mt28">邮件：{{ $web.email_business }}</span>
        <span class="sectitle mt28">{{ $web.email_hr }}</span>
        <span class="sectitle mt28">地址：{{ $web.address }}</span>
        <span class="sectitle mt28">微信公众号：</span>
        <img src="../assets/qrcode.png" class="qrcodeimg mt28" />
      </div>
    </div>
    <div class="flex flex-center recordnumber">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
        {{ $web.copyrig }}
      </a>
      {{ $web.copyrigName }}
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["topMenus"])
  },
  methods: {
    ...mapMutations(["setChoicePage"]),
    toPage(path, pid, classid) {
      this.setChoicePage({ pid, classid });
      this.$router.push({
        path: path,
        query: {
          pid,
          classid
        }
      })
    }
  }
}
</script>
<style scoped >
.foot {
  width: 100%;
  background: #0d1726;
  height: 569px;
  padding: 70px 0 36px 0;
}

.fontcontext {
  padding: 0 143px 0 369px;
}

.prititle {
  font-size: 18px;
  color: #fff;
}

.sectitle {
  font-size: 15px;
  color: #ffffff;
  opacity: 0.5;
  display: block;
  text-decoration: none;
}

.mt40 {
  margin-top: 40px;
}

.mt28 {
  margin-top: 20px;
}

.qrcodeimg {
  width: 92px;
  height: 92px;
  display: block;
}

.recordnumber {
  font-size: 20px;
    color: #fff;
  margin-top: 44px;

}
.recordnumber a{
    font-size: 20px;
    color: #fff;
    text-decoration: none;
  }
  .recordnumber a:hover{
    color: #fff !important;
  }  
</style>