/**
 * 时间格式转换
 * @param {需要转换的值} val 
 * @param {转换的格式，默认年月日} format 
 * @returns 
 */
export default function timeconversion(val, format = "yyyy-mm-dd") {
    let date = new Date(val);
    let y = date.getFullYear();
    let m = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let datatimes = "";
    if (format == "yyyy-mm-dd") {
        datatimes = y + "-" + m + "-" + d;
    } else if (format == "yy-mm") {
        datatimes = y + "-" + m;
    } else if (format == "mm-dd") {
        datatimes = m + "-" + d;
    } else if (format == "yyyy-mm-dd-hh-mm") {
        datatimes = y + "-" + m + "-" + d + " " + h + ":" + mm;
    } else if (format == "yyyy-mm-dd-hh") {
        datatimes = y + "/" + m + "/" + d + " " + h;
    }
    return datatimes;
}

/**
 * Http重复请求处理类
 */
export class HttpRequestStatus{
    constructor() {
        this.salt = Math.random();
    }

    init(){
        let status = sessionStorage.getItem("http-request-status");
        this.request_status = status ? JSON.parse(status) : [];
    }

    // 验证重复请求
    valid(params) {
        this.init();
        this.key = JSON.stringify(params);
        // 处理重复请求
        if(this.request_status.includes(this.key)) return true;
        this.request_status.push(this.key);
        sessionStorage.setItem("http-request-status", JSON.stringify(this.request_status));
        return false;
    }

    // 重置请求状态
    reset(){
        if(this.key){
            this.init();
            let index = this.request_status.indexOf(this.key);
            if(index != -1){
                this.request_status.splice(index,1);
            }
            sessionStorage.setItem("http-request-status", JSON.stringify(this.request_status));
        }
    }
}
