<template>
  <div id="app">
    <router-view />
    <defaultbottom></defaultbottom>
  </div>
</template>
<script>
import defaultbottom from "./components/defaultbottom";
sessionStorage.setItem("http-request-status", "[]");
export default {
  data() {
    return {};
  },
  components: {
    defaultbottom,
  },
};
</script>
<style lang="stylus">
html, body {
  overflow-x: hidden;
  margin: 0;
}

#app {
  height: 100%;
  min-width: 1350Px;
  background: #EFF3F9;
}

div {
  letter-spacing: 1Px;
  box-sizing: border-box;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(245, 248, 253, 0.2) 100%
  ) !important;
  filter: blur(10px);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(245, 248, 253, 0.2) 100%
  ) !important;
  filter: blur(10px);
}

// 公共样式
.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt36 {
  margin-top: 36px;
}

.mt60 {
  margin-top: 60px;
}

// /* flex布局 */
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-column {
  flex-direction: column;
  -webkit-flex-direction: column;
}

.flex-row {
  flex-direction: row;
  -webkit-flex-direction: row;
}

.flex-space {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}

.flex-around {
  align-content: space-around;
  -webkit-align-content: space-around;
}

.flex-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.flex-end-align {
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.flex-start-align {
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

.flex-bottom {
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.flex-center {
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.flex-centers {
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
}

.flex-centersss {
  justify-content: center;
  -webkit-justify-content: center;
}

.flex-centerss {
  -webkit-align-items: center;
  align-items: center;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/* 文字居中对齐 */
.flex-strech {
  align-items: stretch;
}

a:hover, .hovera:hover {
  color: rgba(44, 106, 229, 1) !important;
  cursor: pointer;
}

.hoveraa:hover {
  color: #fff !important;
  cursor: pointer;
}
</style>
