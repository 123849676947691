import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api_interface from './api/interface'
import BaiduMap from 'vue-baidu-map'
import 'element-ui/lib/theme-chalk/index.css' //引入样式，这里是引入全部样式，你也可以单独引入某个组件样式
import ElementUI from 'element-ui';
import 'swiper/css/swiper.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "amfe-flexible";
import axios from "axios";
import website from './config/website'



// axios 请求拦截器
axios.interceptors.response.use(response=>{
  return response.data;
},error => {
  console.log('错误》》',error);
})

Vue.use(BaiduMap, {
  ak: "3aleScuH6uO4qMMD0IMLG4aW7o7smI8I"
})
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);

Vue.prototype.$http = api_interface;
Vue.prototype.$message = ElementUI.Message;
Vue.prototype.$web = website;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')