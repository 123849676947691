//测试单个文件引入路由
export default  [
    {
        path: '/',
        component: ()=>import('../../views/Home.vue'),
        meta: {
         title: "首页",
        }
    },
    {
        path: '/services',
        component: ()=>import('../../views/EcologicalServices.vue'),
        meta: {
         title: "生态服务能力",
        }
    },
    {
        path: '/innovation',
        component: ()=>import('../../views/ApplicationInnovation.vue'),
        meta: {
         title: "应用创新",
        }
    },
    {
        path: '/centralnews',
        component: ()=>import('../../views/CentralNews.vue'),
        meta: {
         title: "中心动态",
        }
    },
    {
        path: '/aboutus',
        component: ()=>import('../../views/AboutUs.vue'),
        meta: {
         title: "关于我们",
        }
    },
    {
        path: '/details',
        component: ()=>import('../../views/Details.vue'),
        meta: {
         title: "文章详情",
        }
    },
    {
        path: '/aidetails',
        component: ()=>import('../../views/AiDetails.vue'),
        meta: {
         title: "AI详情",
        }
    },
]