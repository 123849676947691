import axios from "axios";
import { HttpRequestStatus } from "./utils"

// let BaseUrl = process.env.NODE_ENV === 'production' ? 'http://114.55.127.90:8088/' : '';
let BaseUrl = process.env.NODE_ENV === 'production' ? '' : '';

/**
 * 
 * @param {接口地址} url 
 * @param {参数} params 
 * @param {请求方式} method 
 * @param {是否需要token} istoken 
 * @param {提示语句} message 
 * @param {返回的格式，默认json} responseType 
 */
function request(url, params, method, istoken, message, responseType = "json") {
    let token = "";
    let headerContentType = "application/x-www-form-urlencoded;charset=UTF-8";
    if (method && method.toLocaleUpperCase() == "POST") {
        headerContentType = "application/json;charset=UTF-8";
    }
    if (istoken) {
        token = sessionStorage.getItem('token');
    }
    if (message != "") {
        // 根据框架载入提示语句
        // console.log(message);
    }

    return new Promise((resolve, reject) => {
        // axions 请求参数
        let axiosParams = {
            method: method,
            url: BaseUrl+url,
            data: params,
            headers: {
                'Content-Type': headerContentType,
                'token': token,
            },
            responseType: responseType,
        };

        // 验证是否重复请求
        let hqs = new HttpRequestStatus();
        if(hqs.valid(axiosParams)){
            reject("重复请求无效");
        }else{
            axios(axiosParams).then(res => {
                // 请求完毕，释放状态
                hqs.reset();

                if (message != "") {
                    // 根据框架取消提示语句
                    // console.log(message);
                }
                // console.log(res);
                resolve(res)
            }).catch(err => {
                // 请求完毕，释放状态
                hqs.reset();

                if (message != "") {
                    // 根据框架取消提示语句
                    // console.log(message);
                }
                reject(err);
            })
        }
    })
}

export default request