// 站点配置
module.exports = {
  companyName: '重庆大数据人工智能创新中心有限公司',
  phone: '023-6173 8889',
  email_business: 'business@cicba.cn（商务）',
  email_hr: 'cicba@cicba.cn（简历投递）',
  address: '重庆市九龙坡区前进支路15号渝隆创新大厦20楼',
  mapAddress: '地址：渝隆创新大厦20楼<br />重庆市九龙坡区前进支路15号',
  copyrig: '渝ICP备19013939号-1 ',
  copyrigName:'版权所有© 重庆大数据人工智能创新中心',
  email_resume_delivery: 'cicba@cicba.cn', // 简历投递
}
