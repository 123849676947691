import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    topMenus: {
      services: [],
      innovation: [],
      centralnews: [],
      complete: false
    },
    nowChoicePage: {
      pid: 0,
      classid: 0
    }
  },
  mutations: {
    setChoicePage(state, params) {
      state.nowChoicePage.pid = params.pid;
      state.nowChoicePage.classid = params.classid;
    }
  },
  actions: {
    initTopMenus($store, http) {
      // 生态服务
      let art1 = http.getArticleTypes(1);
      // 应用创新
      let art2 = http.getArticleTypes(2);
      // 中心动态
      let art3 = http.getArticleTypes(3);

      Promise.all([art1, art2, art3]).then(res => {

        // res[0].data.push({value:999,label:'AI能力'});
        if (res[0].code == 0) $store.state.topMenus.services = res[0].data;
        if (res[1].code == 0) $store.state.topMenus.innovation = res[1].data;
        if (res[2].code == 0) $store.state.topMenus.centralnews = res[2].data;
        $store.state.topMenus.complete = true;
      });
    }
  },
  getters: {
    getSubMenus: (state) => (type) => {
      return [...state.topMenus[type]];
    }
  },
  modules: {
  }
})
